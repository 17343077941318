import Head from "next/head";

export default function Home() {
  return (
    <>
      <Head>
        <title>Starnet</title>
        <meta name="description" content="Starnet" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="_globalsign-domain-verification"
          content="q9Y_5DN7dJKEpvzDk8Di2ZH2-43r7XgpCx1KyyLSeZ"
        />
      </Head>
    </>
  );
}
